import Card from "../ProductCard";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { BasketProduct } from "../../lib/redux/basketSlice";
import axios from "axios";
import { komBaseURL } from "../../lib/constants";
import { wedding } from "../../assets/data/categories";

const Wedding = () => {
  const [allProducts, setAllProducts] = useState<BasketProduct[] | undefined>();

  const getItems = useCallback(async () => {
    try {
      // Fetch products for each subcategory in the "wedding" array
      const allResults = await Promise.all(
        wedding.map((item) =>
          axios.get(
            `${komBaseURL}/items/kom-products?product_subcategory=${item.id}`
          )
        )
      );

      // Combine results from all subcategories into one array
      const combinedProducts = allResults.flatMap((res) => res.data);

      // Set the combined products into state
      setAllProducts(combinedProducts);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!allProducts || allProducts.length === 0) {
      getItems();
    }
  }, [getItems]);

  return (
    <main
      id="living"
      className="container min-h-screen w-full px-3 md:px-10 pt-[6rem] md:pt-[7rem] lg:pt-[6rem]"
    >
      <h1 className="text-2xl md:text-4xl xl:text-6xl text-center font-bold my-10 tracking-widest uppercase">
        Wedding Decor
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
        {wedding.map((item) => (
          <Button
            key={item.id}
            className="bg-white hover:bg-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 flex-grow items-center w-full p-4 focus:ring-0"
            onClick={() => {
              var elementToView = document.getElementById(item.id);
              elementToView?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <p className="text-black dark:hover:text-white lg:tracking-widest tracking-wide md:uppercase">
              {item.name}
            </p>
          </Button>
        ))}
      </div>

      {wedding.map((item) => (
        <section key={item.id}>
          <h1 className="text-2xl my-10 md:ml-3 font-semibold md:uppercase">
            {item.name}
          </h1>
          <div
            className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-5 xl:gap-10"
            id={item.id}
          >
            {allProducts
              ?.filter((product) => product.product_subcategory === item.id)
              .map((product, index) => (
                <Card key={index} item={product} />
              ))}
          </div>
        </section>
      ))}
    </main>
  );
};

export default Wedding;
