export const navItems = [
  { name: "Living Room Decor", link: "living_room_decor" },
  { name: "Organizer", link: "organizer" },
  { name: "Bedroom Decor", link: "bedroom_decor" },
  { name: "Dining Decor", link: "dining_decor" },
  { name: "Wedding Decor", link: "wedding_decor" },
  // { name: "Gifting", link: "gifting" },
];

export const mobileNavItems = [
  { name: "Living Room Decor", link: "living_room_decor" },
  { name: "Organizer", link: "organizer" },
  { name: "Bedroom Decor", link: "bedroom_decor" },
  { name: "Dining Decor", link: "dining_decor" },
  { name: "Wedding Decor", link: "wedding_decor" },
  { name: "Blogs", link: "blogs" },
  { name: "Contact Us", link: "contact_us" },
];

export const dropdownContent: Record<string, Array<Record<string, string>>> = {
  "Living Room Decor": [
    { name: "Stool", link: "stool" },
    { name: "Curtain", link: "curtain" },
    { name: "Cushion Cover", link: "cushion_cover" },
    { name: "Mirror", link: "mirror" },
    { name: "Pet Hammock", link: "pet_hammock" },
    { name: "Lamp Shade", link: "lamp_shade" },
    { name: "Room Partition", link: "room_partition" },
    { name: "Swing Chair", link: "swing_chair" },
    { name: "Hanging Table", link: "hanging_table" },
    { name: "Wall Hanging", link: "wall_hanging" },
    { name: "Plant Hanger", link: "plant_hanger" },
  ],
  Organizer: [
    { name: "Magazine Holder", link: "magazine_holder" },
    { name: "Wall Shelf", link: "wall_shelf" },
    { name: "Bags", link: "bag" },
    { name: "Book Stand", link: "book_stand" },
    { name: "Accessories Holder", link: "accessories_holder" },
    { name: "Towel Hanger", link: "towel_hanger" },
  ],
  "Dining Decor": [
    { name: "Table Placemat", link: "table_placemat" },
    { name: "Table Runner", link: "table_runner" },
    { name: "Storage Basket", link: "storage_basket" },
    { name: "Tissue Paper Holder", link: "tissue_paper_holder" },
  ],
  "Bedroom Decor": [
    { name: "Floor Mat", link: "floor_mat" },
    { name: "Baby Bassinet", link: "baby_bassinet" },
    { name: "Hammock Chair", link: "hammock_chair" },
    { name: "Toy Hammock", link: "toy_hammock" },
    { name: "Photo Hanger", link: "photo_hanger" },
    { name: "Dream Catcher", link: "dream_catcher" },
    { name: "Round Wall Hanging", link: "round_wall_hanging" },
  ],
  "Wedding Decor": [
    { name: "Dream Catcher", link: "dream_catcher" },
    { name: "Swing Chair", link: "swing_chair" },
    { name: "Lamp Shade", link: "lamp_shade" },
    { name: "Hammock Chair", link: "hammock_chair" },
    { name: "Table Runner", link: "table_runner" },
    { name: "Wall Hanging", link: "wall_hanging" },
  ],
};
