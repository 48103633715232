import NavigationBar from "../../components/ui/NavBar";
import Wedding from "../../components/categories/Wedding";
import Footer from "../../components/Footer";

const WeddingPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Wedding />
      <Footer />
    </div>
  );
};

export default WeddingPage;
