export const living = [
  { id: "curtain", name: "Curtains" },
  { id: "mirror", name: "Mirrors" },
  { id: "cushion_cover", name: "Cushion Covers" },
  { id: "room_partition", name: "Room Partition" },
  { id: "stool", name: "Stools" },
  { id: "pet_hammock", name: "Pet Hammock" },
  { id: "lamp_shade", name: "Lamp Shades" },
  { id: "swing_chair", name: "Swing Chair" },
  { id: "hanging_table", name: "Hanging Table" },
  { id: "wall_hanging", name: "Wall Hanging" },
  { id: "plant_hanger", name: "Plant Hanger" },
];

export const organizer = [
  { id: "bag", name: "Bags" },
  { id: "magazine_holder", name: "Magazine Holder" },
  { id: "wall_shelf", name: "Wall Shelf" },
  { id: "book_stand", name: "Book Stand" },
  { id: "accessories_holder", name: "Accessories Holder" },
  { id: "towel_hanger", name: "Towel Hangers" },
];

export const dining = [
  { id: "table_placemat", name: "Table Placemat" },
  { id: "table_runner", name: "Table Runners" },
  { id: "storage_basket", name: "Storage Baskets" },
  { id: "tissue_paper_holder", name: "Tissue Paper Holder" },
];

export const bedroom = [
  { id: "dream_catcher", name: "Dream Catchers" },
  { id: "floor_mat", name: "Floor Mats" },
  { id: "hammock_chair", name: "Hammock Chair" },
  { id: "baby_bassinet", name: "Baby Bassinet" },
  { id: "toy_hammock", name: "Toy Hammock" },
  { id: "photo_hanger", name: "Photo Hangers" },
  { id: "round_wall_hanging", name: "Round Wall Hanging" },
];

export const balcony = [
  { id: "swing_chair", name: "Swing Chair" },
  { id: "hammock_chair", name: "Hammock Chair" },
  { id: "plant_hanger", name: "Plant Hanger" },
];

export const wedding = [
  { id: "dream_catcher", name: "Dream Catcher" },
  { id: "swing_chair", name: "Swing Chair" },
  { id: "lamp_shade", name: "Lamp Shades" },
  { id: "hammock_chair", name: "Hammock Chair" },
  { id: "table_runner", name: "Table Runner" },
  { id: "wall_hanging", name: "Wall Hanging" },
];
