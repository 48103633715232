import { Modal } from "flowbite-react";
import { UserAuth } from "../lib/authContext";
import { customStyle } from "../assets/styles/modalStyles";
import GoogleButton from "react-google-button";

const SignInModal = ({
  closeModal,
  modalVisibility,
}: {
  closeModal: () => void;
  modalVisibility: boolean;
}) => {
  const { googleSignIn } = UserAuth();

  return (
    <Modal show={modalVisibility} onClose={closeModal} theme={customStyle}>
      <Modal.Header>Sign In</Modal.Header>
      <Modal.Body className="p-5 md:p-10">
        <div className="flex flex-col items-center pb-5">
          <p className="mb-5">Sign in to checkout</p>
          <GoogleButton
            onClick={() => {
              googleSignIn();
              closeModal();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignInModal;
