import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./screens/Main";
import Privacy from "./screens/policies/Privacy";
import Refund from "./screens/policies/Refund";
import Terms from "./screens/policies/Terms";
import Shipping from "./screens/policies/Shipping";
import Contact from "./screens/Contact";
import Account from "./screens/Account";
import Cart from "./screens/Cart";
import OrderPlaced from "./screens/OrderPlaced";
import Orders from "./screens/Orders";
import Checkout from "./screens/Checkout";
import Wishlist from "./screens/Wishlist";
import LivingPage from "./screens/categories/Living";
import OrganizerPage from "./screens/categories/Organizer";
import BedroomPage from "./screens/categories/Bedroom";
import DiningPage from "./screens/categories/Dining";
import WeddingPage from "./screens/categories/Wedding";
import Collections from "./screens/collections/CollectionsPage";
import Blogs from "./screens/Blogs";
import Blog from "./screens/Blog";
import Altijirah from "./screens/Altijirah";

const AppRoutes = () => {
  return (
    <Routes>
      {/* HOME */}
      <Route path="/" element={<Main />} />

      {/* CATEGORIES */}
      <Route path="/categories">
        <Route path="/categories/living_room_decor" element={<LivingPage />} />
        <Route path="/categories/organizer" element={<OrganizerPage />} />
        <Route path="/categories/bedroom_decor" element={<BedroomPage />} />
        <Route path="/categories/wedding_decor" element={<WeddingPage />} />
        <Route path="/categories/dining_decor" element={<DiningPage />} />
        {/* <Route path="/categories/gifting" element={<GiftingPage />} /> */}
      </Route>

      {/* COLLECTIONS */}
      <Route
        path="/collections/:product_subcategory"
        element={<Collections />}
      />

      {/* POLICIES */}
      <Route path="/privacy_policy" element={<Privacy />} />
      <Route path="/terms_of_use" element={<Terms />} />
      <Route path="/refund_policy" element={<Refund />} />
      <Route path="/shipping_policy" element={<Shipping />} />
      <Route path="/contact_us" element={<Contact />} />

      {/* OTHERS */}
      <Route path="/account" element={<Account />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order_placed" element={<OrderPlaced />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:blog_id" element={<Blog />} />
      <Route path="/altijarah" element={<Altijirah />} />
      {/* <Route path="/quotes" element={<Quotes />} /> 
      <Route path="/quote_placed" element={<QuotePlaced />} /> */}
    </Routes>
  );
};

export default AppRoutes;
